import React from 'react';
import {
  Grid,
  DialogContent,
  Box,
  Dialog,
} from '@material-ui/core';

import { Loader, TransitionRef } from 'components';

import SideInfo from './side-info';
import MiniMapContainer from './mini-map-container';
import TopBar from './panic-modal-header';
import { useDispatch, useSelector } from 'react-redux';
import { clearPanicRouteHistory, togglePanicDetailsModal } from 'actions/panic';
import { IAppState } from 'types';
import { FormattedMessage } from 'react-intl';


const PanicFullscreenModal = () => {
  const dispatch = useDispatch();
  const { panicId, open, shouldBlockMap } = useSelector(({ panic }: IAppState) => ({
    panicId: panic.selectedPanicId,
    open: panic.isFullModalOpen,
    shouldBlockMap: panic.pendingAssignment.indexOf(panic.selectedPanicId) !== -1,
  }));

  const toggleModal = () => {
    // todo: retrigger snackbar if panic status is still one of PENDING_PANINGS when modal is closed
    // this can be reproduced by opening the panic modal from the history page, then closing it without any additional actions
    dispatch(togglePanicDetailsModal({ show: false }));
    dispatch(clearPanicRouteHistory());
  }

  return (
    <Dialog
      open={open}
      onClose={() => toggleModal}
      fullWidth
      fullScreen
      TransitionComponent={TransitionRef}
      scroll="paper"
    >
      <form>
        <TopBar panicId={panicId} toggleModal={toggleModal} />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <SideInfo panicId={panicId} toggleModal={toggleModal} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box position="relative" >
                <Loader
                  isLoading={shouldBlockMap}
                  inContainer
                  text={<FormattedMessage id="agent.pending_assign" defaultMessage="Assigning Agent" />}
                />
                <MiniMapContainer panicId={panicId} />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </form>
    </Dialog>
  );
};


export default PanicFullscreenModal;
