import { combineReducers } from 'redux';
import * as c from '../constants/actions';
import auth from './auth';
import client from './client';
import family from './family';
import modal from './modal';
import panic from './panic';
import settings from './settings';
import snackbar from './snackbar';
import translation from './translation';

const appReducer = combineReducers({
  auth,
  client,
  family,
  panic,
  snackbar,
  translation,
  modal,
  settings,
});

const rootReducer = (state, action) => {
  if (action.type === c.LOGOUT_SUCCESS) {
    // Reset the whole app state when user logs out
    return appReducer({ translation: state.translation }, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
