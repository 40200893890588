import React from 'react';
import { useSelector } from 'react-redux';
import { IAppState } from 'types';
import StyledMarkerContent from '../styled-marker-content';
import { clientMarkerColors } from 'constants/map-settings';
import { AdvancedMarker } from '@vis.gl/react-google-maps';
import { RESOLVED_PANICS } from 'constants/panic';

const PanicMarker = ({ panicId }: { panicId: number }) => {

  const panicDetailsSelector = (state: IAppState) => state.panic.byId[panicId] || {};
  const clientLiveCoordsSelector = ({ client }: IAppState) => client.byId[panicDetails.userId];

  const panicDetails = useSelector(panicDetailsSelector);
  const clientLiveCoords = useSelector(clientLiveCoordsSelector);

  const { user, status } = panicDetails;

  if (!user) {
    return null;
  }
  const isPanicStatusOngoing = !RESOLVED_PANICS.includes(status);
  const position = isPanicStatusOngoing && clientLiveCoords || {
    lat: Number(panicDetails.userLat),
    lng: Number(panicDetails.userLon),
  };

  return (
    <AdvancedMarker
      position={position}
    >
      <StyledMarkerContent status={clientMarkerColors[status]}>
        {user?.fullName}
      </StyledMarkerContent>
    </AdvancedMarker>

  );
};

export default PanicMarker;
