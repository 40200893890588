import React from 'react';
import {  useSelector } from 'react-redux';
import { Box, Button } from '@material-ui/core';
import { CSVLink } from 'react-csv';
import { flatten } from 'flat';
import { FormattedMessage } from 'react-intl';
import { IAppState } from 'types';
import ErrorBoundary from '../../../error-boundary';


const ExportPanicsData = () => {
  const historyList = useSelector(({ panic: { allIds, byId } }: IAppState) => allIds.map((id) => byId[id]));

  const getHistoryFileName = (format: string) => {
    const today = Date.now();
    return `panic-history-${today}.${format}`;
  };
  // todo remove filter and fix flattening :D
  const getData = () => historyList.filter((item) => item.agent).map((item) => {
    const res = {
      ...item,
      // devnote: for some reason flatten crashes with the reportPhoto or reportPhoto object being mixed with null
      // most likely because the value type is expected to stay consistent by CSVLink
      reportPhoto: item.reportPhoto || {},
      reportVideo: item.reportVideo || {},
    };
    return flatten(res);
  });

  return (
    <Box display="flex" alignItems="center">
      <Box ml={1}>
        <ErrorBoundary>
          <Button
            size="small"
            variant="outlined"
            color="default"
            disabled={historyList.length === 0}
            fullWidth
            component={CSVLink as any}
            data={getData()}
            filename={getHistoryFileName('csv')}
            target="_blank"
            style={{ whiteSpace: 'nowrap' }}
          >
            <FormattedMessage
              id="common.export"
              defaultMessage="export"
              values={{
                format: 'csv',
              }}
            />
          </Button>
        </ErrorBoundary>
      </Box>
      <Box ml={1}>
        <ErrorBoundary>
          <Button
            size="small"
            variant="outlined"
            color="default"
            disabled={historyList.length === 0}
            fullWidth
            component={CSVLink as any}
            data={getData()}
            filename={getHistoryFileName('xls')}
            style={{ whiteSpace: 'nowrap' }}
            target="_blank"
          >
            <FormattedMessage
              id="common.export"
              defaultMessage="export"
              values={{
                format: 'xls',
              }}
            />
          </Button>
        </ErrorBoundary>
      </Box>
    </Box>
  );
};


export default ExportPanicsData;
