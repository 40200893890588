import { PanicStatus } from 'types'

export const panicTypeToIntlKey = {
  LIFE_THREATENING: 'panic_type.lifeThreatening',
  SERVICE: 'panic_type.service',
  FAKE: 'panic_type.fake',
  TEST_SOS: 'panic_type.testSos',
}

export const RESOLVED_PANICS = [PanicStatus.canceled, PanicStatus.done]

/**
 * // possible statuses for panics returned by `getWaitingPanicHistory`
 */
export const WAITING_PANICS = [PanicStatus.cancel_request, PanicStatus.deassigned, PanicStatus.pending]
/**
 * // possible statuses for panics returned by `getActivePanicHistory`
 */
export const ACTIVE_PANICS = [
  PanicStatus.accepted_operator,
  PanicStatus.accepted,
  PanicStatus.assigned,
  PanicStatus.inplace,
  PanicStatus.processed,
  PanicStatus.extended
]