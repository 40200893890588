import { createReducer, toIndexedMap } from '../utils';
import * as c from '../constants/actions';
import { PanicStatus } from 'types';
import { ACTIVE_PANICS, WAITING_PANICS } from 'constants/panic';

const initialRouteHistory = {
  user: [],
  agent: [],
};

const initialState = {
  byId: {},
  allIds: [],
  activePanicIds: [],
  waitingPanicIds: [],
  // note: pendingAssignment is used to keep track of panics participating in race, because their real status is still pending/deassigned during the race
  // todo: save the values in session storage for ~1 minute, because of an edge case:
  // create panic -> assign agent during the race -> refresh page ->
  // since the panic is still pending we will play the sound and operator will be able to try another assign
  pendingAssignment: [],
  isFullModalOpen: false,
  selectedPanicId: 0,
  routeHistory: initialRouteHistory,
  isLoading: false,
  error: null,
  totalItems: -1,
};


export default createReducer(initialState, {
  [c.GET_PANIC_BY_ID_REQUEST]: (state) => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.GET_PANIC_BY_ID_SUCCESS]: (state, { payload }) => {
    if (!payload) {
      return { ...state };
    }
    // todo: need to remove the id from allIds or activePanicIds or waitingPanicIds depending on what is the new status
    return {
      ...state,
      waitingPanicIds: WAITING_PANICS.includes(payload.status) ? [...new Set([...state.waitingPanicIds, payload.id])] : state.waitingPanicIds.filter((id) => id !== payload.id),
      activePanicIds: ACTIVE_PANICS.includes(payload.status) ? [...new Set([...state.activePanicIds, payload.id])] : state.activePanicIds.filter((id) => id !== payload.id),
      // allIds: payload.status === PanicStatus.pending && state.allIds.indexOf(payload.id) === -1 ?
      //   [...new Set([...state.allIds, payload.id])].sort((a, b) => b - a) : state.allIds,

      // @TODO: need to check if this still causes an issue:
      // issue will occur when you get panic by id (usually after websocksocket trigger without getting the panic list first)
      byId: { ...state.byId, [payload.id]: payload },
      pendingAssignment: payload.status === PanicStatus.pending ? state.pendingAssignment : state.pendingAssignment.filter((id) => id !== payload.id),
      isLoading: false,
    };
  },
  [c.GET_PANIC_BY_ID_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.GET_PANIC_HISTORY_REQUEST]: (state) => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.GET_PANIC_HISTORY_SUCCESS]: (state, { payload }) => ({
    ...state,
    byId: { ...state.byId, ...toIndexedMap(payload.items) },
    allIds: payload.items.map((el) => el.id),
    isLoading: false,
    totalItems: payload.totalItems,
  }),
  [c.GET_PANIC_HISTORY_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.GET_ACTIVE_PANIC_HISTORY_REQUEST]: (state) => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.GET_ACTIVE_PANIC_HISTORY_SUCCESS]: (state, { payload }) => ({
    ...state,
    byId: { ...state.byId, ...toIndexedMap(payload) },
    activePanicIds: payload.map((el) => el.id),
    isLoading: false,
  }),

  [c.GET_WAITING_PANIC_HISTORY_SUCCESS]: (state, { payload }) => ({
    ...state,
    byId: { ...state.byId, ...toIndexedMap(payload) },
    waitingPanicIds: payload.map((el) => el.id),
    isLoading: false,
  }),
  [c.GET_ACTIVE_PANIC_HISTORY_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.CANCEL_PANIC_SUCCESS]: (state) =>
    // const byId = { ...state.byId };
    // delete byId[payload.id];
    ({
      ...state,
      // waitingPanicIds: WAITING_PANICS.includes(payload.status) ? [...new Set([...state.waitingPanicIds, payload.id])] : state.waitingPanicIds.filter((id) => id !== payload.id),
      // activePanicIds: ACTIVE_PANICS.includes(payload.status) ? [...new Set([...state.activePanicIds, payload.id])] : state.activePanicIds.filter((id) => id !== payload.id),
      // allIds: state.allIds.filter((id) => id !== payload.id),
      // byId,
    }),
  [c.PANIC_DONE_SUCCESS]: (state, { payload }) => {
    // note: might be redunaant because getById is called afterComplete
    // note2: the logic is most likely incorrect and the panic shouldnt be removed
    const byId = { ...state.byId };
    delete byId[payload.id];
    return {
      ...state,
      allIds: state.allIds.filter((id) => id !== payload.id),
      byId,
    };
  },
  [c.TOGGLE_PANIC_DETAILS_MODAL]: (state, { payload }) => ({
    ...state,
    selectedPanicId: payload.id,
    isFullModalOpen: payload.show,
  }),
  [c.REMOVE_PANIC_BY_ID]: (state, { payload }) => ({
    ...state,
    allIds: state.allIds.filter((id) => id !== payload.id),
    activePanicIds: state.activePanicIds.filter((id) => id !== payload.id),
    waitingPanicIds: state.waitingPanicIds.filter((id) => id !== payload.id),
    pendingAssignment: state.pendingAssignment.filter((id) => id !== payload.id),
  }),
  [c.ASSIGN_AGENT_REQUEST]: (state, { payload }) => ({
    ...state,
    pendingAssignment: [...state.pendingAssignment, payload.id],
  }),
  [c.ASSIGN_AGENT_ERROR]: (state, { payload }) => ({
    ...state,
    pendingAssignment: state.pendingAssignment.filter((id) => id !== payload.id),
  }),
  [c.GET_PANIC_ROUTE_HISTORY_SUCCESS]: (state, { payload }) => ({
    ...state,
    routeHistory: payload,
  }),
  [c.CLEAR_PANIC_ROUTE_HISTORY]: (state) => ({
    ...state,
    routeHistory: initialRouteHistory,
  }),
});
